<template>
    <div id="page404">
        <h2>页面丢失了...</h2>
        <el-button @click="goHomePage" type="primary"> 回首页</el-button>
        <a
            href="http://wpa.qq.com/msgrd?v=3&uin=387702624&site=qq&menu=yes"
            target="_blank"
            >在线咨询</a
        >
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
    created () {

    },
    mounted () {

    },
    methods: {
        goHomePage () {
            this.$router.push('/')
        }
    }
}
</script>

<style lang="less" scoped>
#page404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
}
</style>
